const theme = {
  green: "#009640",
  greenLight: "#AFCA0B",
  grey: "#575757",
  greyLight: "#8A8A8A",
  white: "#FFFFFF",
  red: ""
};

export default theme;
